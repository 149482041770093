.categoryTitle {
  color: #243240;
  margin: 0;
  padding-bottom: 12px;
  font-size: 18px;
  font-weight: 700;

  @media (max-width: 620px) {
    display: none;
  }
}

.categoryList {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 11px;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  overscroll-behavior-inline: contain;
  touch-action: pan-x pan-y pinch-zoom;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
    height: 0px;
  }
}
