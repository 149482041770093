.toggle {
  padding: var(--padding-inner-xxs);
  border: 1px solid var(--color-grey-6);
  background: rgba(233, 235, 236, 0.75);
  border-radius: var(--border-radius-sm);
  display: flex;
}

.toggleOption {
  flex: 1 1 0;
  min-width: 0;
  text-align: center;
  line-height: 1;
  padding: calc(var(--padding-inner-xs) + var(--padding-inner-xxs));
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  font-size: var(--font-size-caption);
  font-weight: 500;

  & span {
    font-size: var(--font-size-meta);
    font-weight: 400;
  }

  &.active {
    color: var(--color-primary);
    background: var(--color-white);
    box-shadow: var(--shadow);
  }
}
