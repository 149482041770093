.wrapper {
  display: inline-flex;
  gap: var(--padding-inner-xs);
  border: 1px solid var(--color-form-border);
  border-radius: var(--border-radius-sm);
  padding: var(--padding-inner-xxs) 0px var(--padding-inner-xxs)
    var(--padding-inner-sm);
  background: var(--color-white);
  align-items: center;
  cursor: pointer;
  min-width: 150px;
  min-height: 48px;
  width: 166px;

  &:focus-within:not(.disabled) {
    outline: 2px solid var(--color-primary);
    outline-offset: -1px;
  }

  &.fullWidth {
    display: flex;
  }

  &:hover {
    border-color: var(--color-primary);
  }

  &.hasContent {
    border-color: var(--color-primary);
    color: var(--color-primary);
    font-weight: 500;
  }

  &.disabled {
    background-color: var(--color-form-disabled-background);
    color: var(--color-grey-3);
    border-color: var(--color-grey-4);
    --icon-disabled-color: var(--color-grey-4);
    cursor: not-allowed;
  }
}

.selectionCombobox {
  padding: 10px var(--padding-inner-sm);
  min-height: 44px;
}

.content {
  line-height: 22px;
  min-width: 0;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;

  &.placeholder {
    color: var(--color-grey-2);
  }
}

.popover {
  background: #fff;
  overflow-y: auto;
  border: 1px solid var(--color-grey-6);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow);
  z-index: 30;
}

.item {
  padding: var(--padding-inner-xs) var(--padding-inner-sm);
  cursor: pointer;
  border-bottom: 1px solid var(--color-grey-6);
  display: flex;
  align-items: center;

  &:last-of-type {
    border-bottom: none;
  }

  &.active {
    background: var(--color-hover);
  }
}

.icons {
  color: var(--icon-disabled-color, var(--color-grey-3));
  flex: 0 0 auto;

  &:hover {
    color: var(--icon-disabled-color, var(--color-primary));
  }

  &.open {
    transform: rotateZ(180deg);
  }
}

.label {
  display: block;
  font-size: var(--font-size-caption);
  margin-bottom: var(--padding-inner-xs);
}

.actions {
  white-space: nowrap;
  height: 22px;

  button {
    border: none;
    background: none;
    width: 20px;
    height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & ~ button {
      border-left: 1px solid var(--color-grey-5);
    }
  }
}

.searchTextWrapper {
  height: 66px;
  padding: 12px;
  border-bottom: 1px solid var(--color-grey-6);
}

.searchTextField {
  padding: 0;
  width: 100%;
}

.itemsList {
  overflow-y: auto;
}

.listSectionHeadline {
  font-weight: 500;
}

.clearSearchText {
  color: var(--color-grey-3);
  flex: 0 0 auto;
  border: none;
  background: none;
  height: 32px;
  width: 32px;

  &:hover {
    color: var(--color-primary);
    cursor: pointer;
  }
}

.resetButton {
  height: 42px;
  padding: 12px 16px;
  border: none;
  border-top: 1px solid var(--color-grey-6);
  background: none;
  outline: none;
  line-height: 18px;
  cursor: pointer;

  &.disabled {
    color: var(--color-grey-3);
    background-color: var(color-cta-primary-disabled);
    cursor: not-allowed;
  }
}
