.toastContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: var(--padding-inner-md) var(--padding-inner-sm);
  margin: var(--padding-inner-md) 0;
  border-radius: var(--border-radius-sm);

  &.error {
    background-color: var(--color-error-toast-background);
    border: 2px solid var(--color-error-toast-border);
  }

  &.warning {
    background-color: var(--color-warning-toast-background);
    border: 2px solid var(--color-warning-toast-border);
  }

  &.success {
    background-color: var(--color-success-toast-background);
    border: 2px solid var(--color-success-toast-border);
  }

  .icon {
    flex-shrink: 0;
  }
}
