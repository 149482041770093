.container {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  margin: 15px 0 0 0;
  padding: 0px 5px;
  width: 100%;

  dl {
    flex: 1 0 20%;
    min-width: 140px;
    margin-bottom: 40px;
    h2 {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }

  dd {
    font-size: 12;
    padding-bottom: 5px;

    .link {
      font-size: 12px;
    }
  }

  a {
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: var(--color-orange);
    }
  }
}
