.wrapper {
  display: inline-flex;
  gap: var(--padding-inner-xs);
  border: 1px solid var(--color-form-border);
  border-radius: var(--border-radius-sm);
  padding: var(--padding-inner-xxs) 0px var(--padding-inner-xxs)
    var(--padding-inner-sm);
  background: var(--color-white);
  align-items: center;
  cursor: pointer;
  min-width: 150px;
  min-height: 48px;
  width: 100%;

  &:focus-within:not(.disabled) {
    outline: 2px solid var(--color-focus);
    outline-offset: -1px;
  }

  &.fullWidth {
    display: flex;
  }

  &:hover {
    border-color: var(--color-color-dark-blue);
  }

  &.disabled {
    background-color: var(--color-form-disabled-background);
    border-color: var(--color-grey-4);
  }

  &:not(.open) {
    input[type='text'] {
      padding: 0 var(--padding-inner-sm) 0 0;
    }
  }

  &.open {
    outline: 2px solid var(--color-focus);
    outline-offset: -1px;
    input[type='text'] {
      padding: 0;
    }
  }
}

.content {
  line-height: 22px;
  min-width: 0;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;

  &.placeholder {
    color: var(--color-grey-2);
  }
  &.searchText {
    color: var(--color-text);
  }
}

.resetButton {
  color: var(--color-grey-3);
  flex: 0 0 auto;
  border: none;
  background: none;
  height: 32px;
  width: 32px;
  margin-right: 2px;

  &:hover {
    color: var(--color-primary);
    cursor: pointer;
  }
}

.popover {
  background: #fff;
  overflow-y: auto;
  border: 1px solid var(--color-grey-6);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow);
  z-index: 30;
}

.item {
  padding: var(--padding-inner-xs) var(--padding-inner-sm);
  cursor: pointer;
  border-bottom: 1px solid var(--color-grey-6);
  display: flex;
  align-items: center;

  &:last-of-type {
    border-bottom: none;
  }

  &.active {
    background: var(--color-hover);
  }
}

.label {
  display: block;
  font-size: var(--font-size-caption);
  margin-bottom: var(--padding-inner-xs);
}

.noResults {
  padding: var(--padding-inner-sm);
  color: var(--color-grey-2);
}
