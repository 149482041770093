.icon {
  cursor: pointer;
  display: inline-block;
  color: var(--color-grey-2);
  vertical-align: top;
  margin: 0 4px;
  z-index: 30;

  &:hover {
    color: var(--color-primary);
  }
}
