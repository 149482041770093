.popover {
  font-size: var(--font-size-caption);
  color: var(--color-popover-text);
  background-color: var(--color-popover-background);
  padding: var(--padding-inner-sm);
  border: 1px solid var(--color-popover-border);
  border-radius: var(--border-radius-sm);
  display: flex;
  flex-direction: row;
  max-width: 300px;
  gap: var(--padding-inner-xs);
  align-items: flex-start;
  line-height: 18.53px; // TODO: Magic number?
  box-shadow: var(--shadow);
  z-index: 30;
}

.closeButton {
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: var(--color-grey-2);
  cursor: pointer;

  &:hover {
    color: var(--color-grey-1);
  }
}

.content {
  flex: 1 1 auto;
}

.readMoreLink {
  display: block;
  padding-top: var(--padding-inner-xs);
  color: var(--color-primary);
}
