.overlay {
  background: var(--color-overlay);
  display: grid;
  place-items: center;
  z-index: 20; // Make sure it goes above takeover/touchscroll ads
}

.dialog {
  position: relative;
  background-color: var(--color-white);
  border-radius: var(--border-radius-md);
  min-width: 250px;
  width: 100%;
  max-width: 53.625rem;
  box-shadow: var(--shadow);
}

.header {
  background: var(--color-white);
  border-bottom: 1px solid var(--color-grey-6);
  box-shadow: var((--shadow));
  z-index: 40;

  h3 {
    padding: var(--padding-inner-sm) var(--padding-inner-md);
    text-align: center;
  }
}

.subHeader {
  border-top: 1px solid var(--color-grey-6);
  padding: var(--padding-inner-xs) var(--padding-inner-md);

  &:empty {
    padding: 0;
  }
}

.body {
  position: relative;
  padding: var(--padding-inner-md);
  margin: 0;
}

.actions {
  background: var(--color-white);
  border-top: 1px solid var(--color-grey-6);
  padding: var(--padding-inner-sm) var(--padding-inner-md);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  box-shadow: 0px -2px 4px rgba(36, 50, 64, 0.1);
  z-index: 40;

  & > *:only-child {
    margin-left: auto;
  }
}

.closeIcon {
  color: var(--color-grey-2);
  position: absolute;
  top: var(--padding-inner-sm);
  right: var(--padding-inner-md);
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;

  &:hover {
    color: var(--color-primary);
  }
}

.clear {
  color: var(--color-grey-3);
  position: absolute;
  top: 20px;
  right: var(--padding-inner-md);
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-caption);
  cursor: pointer;
}

.backIcon {
  color: var(--color-grey-2);
  position: absolute;
  top: var(--padding-inner-sm);
  left: var(--padding-inner-md);
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;

  &:hover {
    color: var(--color-primary);
  }
}
