.card {
  width: calc(25% - 5px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 20px; /* Fastholder top padding */
  margin-right: 5px; /* Tilføj højre margin til alle kort undtagen det sidste i hver række */

  &:nth-child(4n) {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    width: calc(50% - 5px);

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: 480px) {
    width: calc(50% - 5px);
    margin-right: 5px;

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }

  .wrapper {
    width: 100%;
    height: 160px;
    position: relative;
    margin-bottom: 5px;

    @media (max-width: 768px) {
      height: 250px;
    }

    @media (max-width: 700px) {
      height: 200px;
    }

    @media (max-width: 600px) {
      height: 160px;
    }

    @media (max-width: 480px) {
      height: 145px;
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }

    .price {
      position: absolute;
      bottom: 8px;
      right: 0;
      background: rgba(255, 255, 255, 0.75);
      padding: 2px 6px;
      font-size: var(--font-size-caption);
      color: var(--color-white);
      background-color: var(--color-tertiary);
    }
  }

  .title {
    font-size: var(--font-size-caption);
    display: inline-block;
    padding: 0 4px;
  }
}
