.fullWidth {
  width: 100%;
}

.halfWidth {
  width: calc((100% - var(--padding-inner-xs)) / 2);
}

.desktopWrapper {
  width: 166px;
}