.container {
  background-color: var(--color-tertiary);
  padding: var(--padding-inner-xxl) var(--padding-inner-lg);
  margin-bottom: var(--padding-inner-sm);

  .successMessage {
    color: var(--color-white);
    margin: 0;
    text-align: center;

    h4 {
      font-size: var(--font-size-subtitle);
      margin-bottom: var(--padding-inner-sm);
    }

    p {
      margin: 0;
    }

    .checkmarkIcon {
      fill: var(--color-white);
      color: var(--color-white);
      background-color: var(--color-white);
    }
  }

  .form {
    gap: var(--padding-inner-xl);
    width: 100%;

    .errorMessageWrapper {
      width: 100%;
      display: block;
      margin-bottom: 8px;

      .errorMessage {
        color: var(--color-error);
        font-size: var(--font-size-caption);
      }
    }

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      gap: var(--padding-inner-sm);

      @media (max-width: 740px) {
        flex-direction: column;
        gap: var(--padding-inner-sm);
      }

      .text {
        margin: 0;
        color: var(--color-white);
        width: 50%;

        @media (max-width: 740px) {
          min-width: 100%;
          text-align: center;
        }

        @media (max-width: 600px) {
          text-align: left;
        }
      }

      .inputButtonWrapper {
        display: flex;
        flex-direction: row;
        width: 50%;
        gap: 8px;

        @media (max-width: 740px) {
          flex-direction: column;
        }

        @media (max-width: 600px) {
          gap: var(--padding-inner-sm);
          width: 100%;
        }

        .formInput,
        .submitButton {
          height: 40px;
        }

        .formInput {
          flex-grow: 1;
          padding: 0 var(--padding-inner-xs);
          border: 1px solid var(--color-grey-6);
          border-radius: var(--border-radius);
          background-color: var(--color-white);
          color: var(--color-text);
          font-size: var(--font-size-caption);

          @media (max-width: 600px) {
            width: 100%;
            margin: 8px 0px 0px 0px;
          }
        }

        .errorBorder {
          border: 1px solid red;
        }

        .submitButton {
          padding: var(--padding-inner-xxs) var(--padding-inner-xs);
          border: 1px solid var(--color-white);
          border-radius: var(--border-radius);
          background-color: transparent;
          color: var(--color-white);
          font-size: var(--font-size-body);
          margin: 0;
          cursor: pointer;
          white-space: nowrap;
        }
      }
    }
  }
}
