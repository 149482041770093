.headline {
  color: #002e5c;
  font-size: 16px;
  margin: 0;
  padding: 5px;
  font-weight: 400;
  margin-top: 10px;
}

.blogContainer {
  display: flex;
  flex-direction: row;
  height: 260px;
  align-items: stretch;
  gap: 5px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }

  .blogLargeWrapper,
  .blogSmallWrapper {
    a:hover .blogLargeImage,
    a:hover .imageWrapper .blogSmallImage {
      transform: scale(1.2);
    }
  }

  .blogLargeWrapper {
    position: relative;
    overflow: hidden;

    .imageWrapper {
      .blogLargeImage {
        height: 260px;
        transform: translateZ(0) scale(1);
        transition: transform 5.5s;
      }
    }

    .blogText {
      background-color: rgba(0, 0, 0, 0.25);
      bottom: 0;
      color: #fff;
      left: 0;
      max-height: 40%;
      overflow: hidden;
      padding: 10px;
      position: absolute;
      right: 0;
      z-index: 5;
    }
  }

  .blogSmallWrapper {
    gap: 5px;
    overflow: hidden;
    width: 100%;

    article {
      flex: 1;
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        height: 125px;
        width: 100%;
      }

      a {
        flex: 1;
        display: flex;
        flex-direction: row;

        .blogText {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 100%;
          background-color: #fff;
          overflow: hidden;
          padding: 10px 20px 10px 10px;
          width: 60%;

          @media (max-width: 768px) {
            width: 70%;
            height: 125px;
          }
        }

        .imageWrapper {
          width: 40%;
          position: relative;
          overflow: hidden;
          .blogSmallImage {
            min-width: 100%;
            height: 100%;
            transform: translate3d(0, 0, 0) scale(1);
            transition: transform 5.5s;

            @media (max-width: 768px) {
              width: 30%;
            }
          }
        }
      }
    }
  }

  .blogLargeWrapper,
  .blogSmallWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;

    a {
      text-decoration: none;

      &:hover {
        .description {
          color: var(--color-orange);
          text-decoration: underline;
        }
      }
    }

    h5 {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
      padding-bottom: 2px;
    }

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
