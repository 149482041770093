.cardContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  gap: var(--padding-inner-sm);
  margin: 15px 0px;

  @media (max-width: 775px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 12px;
  }
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex: 1;
  padding: 24px 16px;
  gap: 24px;

  @media (max-width: 775px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 12px;
  }

  &.partnerCard {
    background-color: var(--color-tertiary);
    color: var(--color-white);
  }

  &.bilbasenCard {
    background-color: var(--color-white);
  }

  .imageWrapper {
    .bilbasenImage {
      min-width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 50%;

      @media (max-width: 775px) {
        min-width: 60px;
        height: 60px;
      }
    }

    .imageCircle {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      position: relative;
      background-color: #063d74;
      overflow: hidden;

      @media (max-width: 775px) {
        width: 60px;
        height: 60px;
      }

      .partnerLogo {
        position: absolute;
        top: 23%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 58px;
        height: auto;

        @media (max-width: 775px) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 48px;
        }
      }

      .partnerImage {
        width: 120px;
        height: 78px;
        left: 50%;
        top: 70%;
        transform: translate(-50%, -50%);
        position: absolute;

        @media (max-width: 775px) {
          display: none;
        }
      }
    }
  }

  .cardContent {
    width: 100%;

    .title {
      font-size: 18px;
      font-weight: 700;
      padding-bottom: 8px;
      margin: 0;
      @media (max-width: 775px) {
        padding-bottom: 4px;
      }
    }

    .text {
      font-size: 18px;
      font-weight: 400;
      margin: 0;
      padding-bottom: 16px;
    }

    .title,
    .text {
      &.black {
        color: var(--color-text);
      }

      &.white {
        color: var(--color-white);
      }
    }

    .button {
      padding: 0 16px;
      font-size: 16px;
      border-radius: 4px;
      height: 40px;

      @media (max-width: 775px) {
        min-width: 300px;
      }

      @media (max-width: 600px) {
        min-width: 100%;
      }

      a {
        text-decoration: none;
      }

      &.partnerButton {
        background-color: var(--color-blue);
        color: var(--color-white);

        &:hover {
          background-color: #3d94eb;
        }
      }
    }
  }
}
