.description {
  margin-bottom: var(--padding-inner-sm);
  min-width: 0;

  &:last-of-type {
    margin: 0;
  }

  &:has(> .description) {
    margin: 0;
  }
}

.popoverTitle {
  display: inline-block;
  margin: 8px;
  position: relative;
  bottom: -1px;
}
