.container {
  padding: 10px 5px 10px 5px;

  h2 {
    font-size: var(--font-size-body);
  }

  p {
    font-size: var(--font-size-meta);
    line-height: 24px;
  }

  .wrapper {
    ul {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      list-style-type: none;
      overflow: hidden;
      padding: 0;
      margin: -5px;

      li {
        flex: 0 1 auto;
        margin: 5px;
      }

      a {
        font-size: var(--font-size-meta);
        text-decoration: none;
        color: inherit;

        &:hover {
          text-decoration: underline;
          color: var(--color-orange);
        }
      }
    }
  }
}
