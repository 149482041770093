.range {
  display: flex;
  margin-bottom: var(--padding-inner-sm);
}

.control {
  flex: 1 1 0;
  min-width: 0;

  label {
    display: block;
    font-size: var(--font-size-caption);
    margin-bottom: var(--padding-inner-xs);
  }
}

.divider {
  color: var(--color-grey-2);
  padding-top: 24px;
  flex: 0 0 var(--padding-inner-md);
  display: flex;
  justify-content: center;
  align-items: center;
}

.resetButton {
  color: var(--color-grey-3);
  flex: 0 0 auto;
  border: none;
  background: none;
  height: 32px;
  width: 24px;

  &:hover {
    color: var(--color-primary);
    cursor: pointer;
  }
}
