.dialog {
  display: flex;
  flex-direction: column;
  position: fixed;
  background: var(--color-white);
  top: 0;
  bottom: 0;
  height: 100%;
  right: 0;
  z-index: 1200;
  max-width: 100%;
  min-width: 300px;
  width: 650px;
  border-radius: 0;

  @media (min-width: 500px) {
    max-width: 75%;
  }
}

.dialogBody {
  padding: var(--padding-inner-md);
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: var(--padding-inner-xs);
  overflow-y: scroll;
  overflow-x: hidden;
}

.search {
  margin-bottom: var(--padding-inner-md);
}
