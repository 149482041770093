.carSearchList {
  .imageOverlay {
    position: relative;
    overflow: hidden;
  }

  .imageOverlay::after {
    content: '';
    display: block;
    position: absolute;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    z-index: 13;
    width: 100%;
    height: 50%;
    bottom: 0;
    border-radius: 8px;
  }

  .imageOverlayTitle {
    position: absolute;
    z-index: 14;
    left: 50%;
    top: 86%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    margin: 0;
  }

  .categoryImage {
    position: relative;
    min-width: 140px;
    height: 186px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 8px;

    transform: translateZ(0) scale(1);
    transition: transform 0.4s;
  }

  .categoryImage,
  .imageOverlay {
    border-radius: 8px;
    &:hover {
      .categoryImage {
        transform: scale(1.2);
      }

      .imageOverlayTitle {
        text-decoration: underline;
      }
    }
  }
}
