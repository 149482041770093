.wrapper {
  display: inline-flex;
  gap: var(--padding-inner-xs);
  border: 1px solid var(--color-form-border);
  border-radius: var(--border-radius-sm);
  padding: var(--padding-inner-xxs) 0px var(--padding-inner-xxs)
    var(--padding-inner-sm);
  background: var(--color-white);
  align-items: center;
  cursor: pointer;
  min-width: 150px;
  min-height: 48px;
  width: 166px;

  &:focus-within:not(.disabled) {
    outline: 2px solid var(--color-primary);
    outline-offset: -1px;
  }

  &.fullWidth {
    display: flex;
    width: 100%;
  }

  &:hover {
    border-color: var(--color-primary);
  }

  &.hasContent {
    border-color: var(--color-primary);
    color: var(--color-primary);
    font-weight: 500;
  }

  &.disabled {
    background-color: var(--color-form-disabled-background);
    border-color: var(--color-grey-4);
    --icon-disabled-color: var(--color-grey-4);
  }
}

.content {
  line-height: 22px;
  min-width: 0;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;

  &.placeholder {
    color: var(--color-grey-2);
  }
}

.icons {
  color: var(--icon-disabled-color, var(--color-grey-3));
  flex: 0 0 auto;

  &:hover {
    color: var(--icon-disabled-color, var(--color-primary));
  }

  &.open {
    transform: rotateZ(180deg);
  }
}

.actions {
  white-space: nowrap;
  height: 22px;

  button {
    border: none;
    background: none;
    width: 20px;
    height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & ~ button {
      border-left: 1px solid var(--color-grey-5);
    }
  }
}

.selectionCombobox {
  padding: 10px var(--padding-inner-sm);
  min-height: 44px;
}
