.container {
  background-color: var(--color-white);
  margin-bottom: var(--padding-inner-sm);

  .title {
    color: #002e5c;
    font-size: 16px;
    margin: 0;
    padding: 5px;
    font-weight: 400;
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
  }

  .buttonContainer {
    text-align: center;
    padding: 10px 0 10px 0;

    .button {
      text-decoration: none;
      padding: 0;
      border: none;
      background: none;
      cursor: pointer;
      font-size: var(--font-size-body);

      .buttonText {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;
      }
    }
  }
}
