.wrapper {
  display: inline-flex;
  border: 1px solid var(--color-form-border);
  border-radius: var(--border-radius-sm);
  padding: calc(var(--padding-inner-xxs) - 1px);
  background: var(--color-white);
  align-items: center;

  &:focus-within {
    outline: 2px solid var(--color-focus);
    outline-offset: -1px;
  }

  &.fullWidth {
    display: flex;
  }

  &:hover {
    border-color: var(--color-primary);
  }

  &.hasContent {
    border-color: var(--color-primary);
    color: var(--color-primary);
    font-size: var(--font-size-body);
  }

  &.disabled {
    background-color: var(--color-form-disabled-background);
    border-color: var(--color-grey-4);
    --icon-disabled-color: var(--color-grey-4);
    cursor: not-allowed;
  }

  &.error {
    border-color: (var(--color-error))
  }
}

.input {
  font: inherit;
  font-size: var(--font-size-body);
  line-height: 22px;
  border: none;
  background: none;
  min-width: 0;
  display: block;
  flex: 1 1 auto;
  padding: calc(var(--padding-inner-xs) + 1px)
    calc(var(--padding-inner-xxs) + var(--padding-inner-xs));

  &.noPaddingRight {
    padding-right: unset;
  }

  &:focus-visible {
    outline: none;
  }

  &.disabled {
    cursor: not-allowed;
  }
}
