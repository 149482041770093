.showAllMakes {
  display: flex;
  justify-content: center;
  margin-top: var(--padding-inner-xs);
}

.letterLinks {
  display: flex;
  flex-direction: row;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-caption);
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-decoration: none;
    border-radius: var(--border-radius-sm);

    &:hover {
      background-color: var(--color-hover);
    }
  }
}

.makes {
  margin-left: -4px; // TODO: Hacky way to align...

  a {
    grid-column: span 2;
  }
}

.makeOption {
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.allMakesCaption {
  margin-top: var(--padding-inner-md, 24px);
}
