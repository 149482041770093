.filtersOuterWrapper {
  padding-bottom: var(--padding-inner-xxl, 48px);
}

.filtersInnerWrapper {
  @media (min-width: 752px) {
    max-width: 688px;
  }
  @media (max-width: 751px) {
    max-width: 530px;
  }
}

.searchBtn {
  height: 44px;
  min-width: 166px;
  padding: 10px var(--padding-inner-sm);

  @media (max-width: 620px) {
    width: 100%;
  }
}

.otherBtns {
  display: flex;
  gap: var(--padding-inner-sm, 16px);
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 621px) {
    padding-top: var(--padding-inner-xs, 8px);
  }

  @media (max-width: 620px) {
    padding-top: var(--padding-inner-sm, 16px);
  }
}
