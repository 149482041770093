.wrap {
  --thumb-size: 36px;
  --track-height: 4px;
}

.multi-track {
  position: relative;
  height: max(var(--track-height), var(--thumb-size));

  input[type='range'] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: var(--thumb-size);
    background: none;
    margin: 0;
    pointer-events: none;
    cursor: grab;
    z-index: 1;

    &:focus,
    &:active {
      outline: none;
      z-index: 10;
    }

    &.hasValue,
    &:active {
      &::-webkit-slider-thumb {
        border-color: var(--color-primary);
        // This is not an ideal way ot setting the image, as we have to hardcode the color in, however I don't know a better solution to setting this in a shadow dom. Potentially we could leverage some SCSS mixin magic?
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 3v10M8 3v10M4 3v10' stroke='%23243240' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      }

      &::-moz-range-thumb {
        border-color: var(--color-primary);
        // This is not an ideal way ot setting the image, as we have to hardcode the color in, however I don't know a better solution to setting this in a shadow dom. Potentially we could leverage some SCSS mixin magic?
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 3v10M8 3v10M4 3v10' stroke='%23243240' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      }
    }

    &:active {
      &::-webkit-slider-thumb {
        cursor: grabbing;
        transform: scale(0.88);
      }

      &::-moz-range-thumb {
        cursor: grabbing;
        transform: scale(0.88);
      }
    }

    &:focus {
      &::-webkit-slider-thumb {
        outline: 2px solid var(--color-focus);
        outline-offset: 2px;
      }

      &::-moz-range-thumb {
        outline: 2px solid var(--color-focus);
        outline-offset: 2px;
      }
    }

    &::-moz-range-track,
    &::-moz-range-thumb,
    & {
      appearance: none;
    }

    &::-webkit-slider-runnable-track,
    &::-webkit-slider-thumb,
    & {
      appearance: none;
      -webkit-appearance: none;
    }

    &::-webkit-slider-thumb {
      pointer-events: auto; /* re-enable pointer-events */
      height: var(--thumb-size);
      width: var(--thumb-size);
      border-radius: 50%;
      background-color: var(--color-white);
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 3v10M8 3v10M4 3v10' stroke='%238C949B' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      border: 2px solid var(--color-grey-4);
      box-shadow: var(--shadow);
      transition: transform 100ms ease-in-out;
    }

    &::-moz-range-thumb {
      pointer-events: auto; /* re-enable pointer-events */
      height: var(--thumb-size);
      width: var(--thumb-size);
      border-radius: 50%;
      background-color: var(--color-white);
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 3v10M8 3v10M4 3v10' stroke='%238C949B' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      border: 2px solid var(--color-grey-4);
      box-shadow: var(--shadow);
      transition: transform 100ms ease-in-out;
    }
  }
}

.multi-track-track {
  position: absolute;
  left: calc(var(--thumb-size) / 2);
  right: calc(var(--thumb-size) / 2);
  top: calc(var(--thumb-size) / 2 - var(--track-height) / 2);
  height: var(--track-height);
  background-color: var(--color-grey-5);
  pointer-events: none;
  border-radius: calc(var(--track-height) / 2);
  z-index: 0;
}

.srOnly {
  position: absolute;
  clip-path: inset(50%);
}

.output {
  display: none;
  pointer-events: none;
  position: absolute;
}
