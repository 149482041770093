.sectionTitle {
  font-weight: 500;
  margin-bottom: var(--padding-inner-xs, 8px);
  margin-top: var(--padding-inner-sm, 16px);
}

.models {
  display: grid;
  grid-template-columns: 1fr;
}

.resetButton {
  color: var(--color-grey-3);
  flex: 0 0 auto;
  border: none;
  background: none;
  height: 32px;
  width: 32px;
  margin-right: 2px;

  &:hover {
    color: var(--color-primary);
    cursor: pointer;
  }
}
